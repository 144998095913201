import React from 'react'
import Header from "./components/Header";
import Footer from "./components/Footer";
import Search from "./components/Search";
import "./Home.css";
const Home = () => {
  return (
    <div className='mcontainer'>
        <div className='headercontainer'>
            <Header/>
        </div>
        <Search/>
        <div className='footercontainer'>
            <Footer/>
        </div>
    </div>
  )
}

export default Home