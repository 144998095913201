import React from 'react'
import "./Errorpage.css"
import {useNavigate} from "react-router-dom";

const Errorpage = () => {
  let navigate = useNavigate();
  let path = "#" 
  const routeChange = (event) =>{ 
    let id = event.target.id
    if (id==='homepage'){
      path = "/home";
    }
    navigate(path);
  }
  return (
    <div className='main'>
      <div className='errorcode'>404</div>
      <div className='description'>Aradığınız 'şey' bulunamadı.</div>
      <div className='buttondiv'><button onClick={routeChange} id="homepage" className='anasayfa'>Ana Sayfa</button></div>
      
    </div>
  )
}

export default Errorpage