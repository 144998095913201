import React from 'react'
import "./Search.css"
import { IconContext } from "react-icons";
import { MdSearch } from "react-icons/md";
import testimg from "../assets/testimg.png";
import { MdOutlineStorefront } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";




const Search = () => {

  return (
    <div className='fullcontainer'>
      <div className='title'>Hangi Ürünü Arıyorsun?
      </div>
      <div className='doubled'>
        <div className='searchbar'>
          <IconContext.Provider value={{
            color: "#1B8552",
            size: "3em"
          }}>
            <MdSearch />
          </IconContext.Provider>
          <input className='searchbox' placeholder='Armut'></input>
        </div>
        <div className='kategoriler'>
          <div className='kategori'>
            <select className='select'>
              <option value="0">Şehir</option>
              <option value="1">Ankara</option>
            </select>
          </div>
          <div className='kategori'>
            <select className='select'>
              <option value="0">Gönderim Şekli</option>
              <option value="1">Sadece Elden</option>
              <option value="2">Sadece Uzaktan</option>
              <option value="3">Elden ve Uzaktan</option>
            </select>

          </div>
          <div className='kategori'>
            <select className='select'>
              <option value="0">Ürün Tipi</option>
              <option value="1">Ankara</option>
            </select>

          </div>
        </div>
      </div>
      <div className='vitrinyazi'>Vitrindekiler</div>
      <div className='vitrinmain'>

        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
        <div class="item">
          <div className='imageholder'>
            <img alt="" src={testimg} className='icon'></img>
          </div>
          <div className='informationcollumn'>
            <div className='productname'>
              <IconContext.Provider value={{ color: "black", className: "store-icon", size: "1.5em" }}>
                <MdOutlineStorefront />
              </IconContext.Provider>
              Buğday
            </div>
            <div className='location'>
              <IconContext.Provider value={{ color: "black", className: "loc-icon", size: "1.5em" }}>
                <MdOutlineLocationOn />
              </IconContext.Provider>
              Konya
            </div>
          </div>
        </div>
      </div>



    </div>
  )
}

export default Search