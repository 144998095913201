import React from 'react'
import "./Header.css"
import logo from "../assets/logo-square.png"
import {useNavigate} from "react-router-dom";



const Header = () => {
  let navigate = useNavigate();
  let path = "#" 
  const routeChange = (event) =>{ 
    let id = event.target.id
    if (id==='homepage'){
      path = "/home";
    }else if (id==='about'){
      path = "/about";
    }else if (id==='privacy'){
      path = "/legal";
    }else if (id==='login'){
      path = "/login";
    }else if (id==='signup'){
      path = "/signup";
    }
    navigate(path);
  }

  
  return (
    <div className='main-header-card'>
        <div className='header-left'>
          <div className='header-logo'>
              <img onClick={routeChange} id="homepage" className="foto"src={logo} alt="logo"></img>
          </div>
          <div onClick={routeChange} id="homepage" className='tarlanet-yazi'><span onClick={routeChange} id="homepage"  className='tarla'>Tarla</span>Net</div>
          <button onClick={routeChange} id="homepage"className='homepage-button'>Ana Sayfa</button>
          <button onClick={routeChange} id="about"className='aboutus-button'>Hakkımızda</button>
          <button onClick={routeChange} id="privacy" className='privacy-policy-button'>Gizlilik</button>
          </div>
          
        <div className='header-right'>
            <div className='login-button-contianer'>
                <button onClick={routeChange} id="login" className='login-button'>Giriş Yap</button>
                <button onClick={routeChange} id="signup" className='signup-button'>Kayıt Ol</button>
            </div>
        </div>
    </div>
  )
}

export default Header