import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Signup.css";
import { IconContext } from "react-icons";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineLock } from "react-icons/md";
import { FaIdCard } from "react-icons/fa6";
import {useNavigate} from "react-router-dom";
import rightphoto from "./assets/loginphoto.png";

const Signup = () => {
  let navigate = useNavigate();
  let path = "#" 
  const routeChange = (event) =>{ 
    let id = event.target.id
    if (id==='login'){
      path = "/login";
    }
    navigate(path);
  }
  return (
    <div className='wholecontainer'>
      <div className='header_container'>
        <Header/>
      </div>
      <div className='headerdiv'>
        <span className='headergiris'>Kayıt Ol</span>
        <span className='subtitlegiris'>Eğer kayıtlıysanız <span onClick={routeChange} id="login" className='kayitolun'>Giriş Yapın</span></span>

      </div>
      <div className='horizontaldiv'>
        <div className='leftdiv'>
        <span className='textboxuptext'>Ad ve Soyad</span>
          <div className='phonediv'>
            <IconContext.Provider value={{
              color: "#1B8552",
              size: "2.4em"
            }}>
              <FaIdCard />
            </IconContext.Provider>
            <input id="isim" className='phonetextbox' type="text" placeholder="Tam Adınızı Giriniz"></input>
          </div>
          <span className='textboxuptext'>Telefon Numarası</span>

          <div className='phonediv'>
            <IconContext.Provider value={{
              color: "#1B8552",
              size: "2.5em"
            }}>
              <MdOutlinePhone />
            </IconContext.Provider>
            <input id="numara" className='phonetextbox' type="number" placeholder="Numaranızı Giriniz"></input>
          </div>

          <span className='textboxuptext'>Şifre</span>

          <div className='passworddiv'>
            <IconContext.Provider value={{
              color: "#1B8552",
              size: "2.5em"
            }}>
              <MdOutlineLock />

            </IconContext.Provider>
            <input id="sifre" className='passwordtextbox' type="password" placeholder="Şifrenizi Giriniz"></input></div>

          <div className='leftbottom'>
            <div className='rememberdiv'>
              <input className='rememberme' type='checkbox'></input>
              <span className='benihatirla'>Beni Hatırla</span>
              </div>
          </div>

            <button className='girisyap'>Kayıt Ol</button>
        </div>
        <div className='rightdiv'>
          <img src={rightphoto} className='rightphoto' alt=""></img>
        </div>

      </div>
        <div className='footerdiv'>
          <Footer/>
        </div>
      
    </div>
  )
}

export default Signup