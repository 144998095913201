import React from 'react'
import { SpeedInsights } from "@vercel/speed-insights/react"
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import About from "./About";
import Signup from './Signup';
import Login from './Login';
import Home from './Home';
import Errorpage from "./Errorpage"
import Legal from "./Legal"


const App = () => {
  return (
    <Router>
            <Routes>
              <Route path="*" element={<Errorpage/>} />
                <Route exact path="/about" element={<About />} />
                <Route exact path="/signup" element={<Signup />} />
                <Route exact path="/" element={<Home />} />
                <Route exact path="" element={<Home />} />
                <Route exact path="/home" element={<Home />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/legal" element={<Legal />} />
            </Routes>
            <SpeedInsights/>
        </Router>

  )
}

export default App