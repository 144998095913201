import React from 'react';
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./Login.css"
import { IconContext } from "react-icons";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineLock } from "react-icons/md";
import rightphoto from "./assets/signupphoto.png";
import {useNavigate} from "react-router-dom";





const Login = () => {
  let navigate = useNavigate();
  let path = "#" 
  const routeChange = (event) =>{ 
    let id = event.target.id
    if (id==='signup'){
      path = "/signup";
    }
    navigate(path);
  }
  return (
    <div className='mcontainer'>
      <div className='header_container'>
        <Header />
      </div>
      <div className='headerdiv'>
        <span className='headergiris'>Giriş Yap</span>
        <span className='subtitlegiris'>Eğer kayıtlı değilseniz <span onClick={routeChange} id="signup" className='kayitolun'>Kayıt Olun</span></span>

      </div>
      <div className='horizontaldiv'>
        <div className='leftdiv'>
          <span className='textboxuptext'>Telefon Numarası</span>
          <div className='phonediv'>
            <IconContext.Provider value={{
              color: "#1B8552",
              size: "2.5em"
            }}>
              <MdOutlinePhone />
            </IconContext.Provider>
            <input className='phonetextbox' type="number" placeholder="Numaranızı Giriniz" />
          </div>

          <span className='textboxuptext'>Şifre</span>

          <div className='passworddiv'>
            <IconContext.Provider value={{
              color: "#1B8552",
              size: "2.5em"
            }}>
              <MdOutlineLock />

            </IconContext.Provider>
            <input className='passwordtextbox' type="password" placeholder="Şifrenizi Giriniz"></input></div>

          <div className='leftbottom'>
            <div className='rememberdiv'>
              <input className='rememberme' type='checkbox'></input>
              <span className='benihatirla'>Beni Hatırla</span>
              </div>
            <div className='sifremiunuttum'>Şifrenizi mi unuttunuz?</div>
          </div>

            <button className='girisyap'>Giriş Yap</button>
        </div>
        <div className='rightdiv'>
          <img src={rightphoto} className='rightphoto' alt=""></img>
        </div>

      </div>
      <div className='footercontainer'>
            <Footer/>
      </div>
    </div>

  )
}

export default Login